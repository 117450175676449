export const ColorTheme = {
  Transparent: 'transparent',

  Black: '#171717',
  White: '#FFFFFF',
  Cycle: '#2B44E7',

  Grey50: 'var(--color-grey-50)',
  Grey100: 'var(--color-grey-100)',
  Grey200: 'var(--color-grey-150)',
  Grey300: 'var(--color-grey-200)',
  Grey400: 'var(--color-grey-350)',
  Grey500: 'var(--color-grey-500)',
  Grey600: 'var(--color-grey-550)',
  Grey700: 'var(--color-grey-600)',
  Grey750: 'var(--color-grey-750)',
  Grey800: 'var(--color-grey-800)',
  Grey850: 'var(--color-grey-850)',
  Grey900: 'var(--color-grey-950)',

  Blue100: '#F1F3FE',
  Blue200: '#E2E6FD',
  Blue300: '#CFD5FC',
  Blue400: '#919FF7',
  Blue500: '#4058F2',
  Blue600: '#102DEA',
  Blue700: '#0F29D2',
  Blue800: '#081777',
  Blue900: '#050D43',

  Green100: '#F1FEF7',
  Green200: '#E2FDEE',
  Green300: '#CFFCE3',
  Green400: '#92F7BF',
  Green500: '#40F290',
  Green600: '#10EA72',
  Green700: '#0FD267',
  Green800: '#08773A',
  Green900: '#054322',

  Yellow100: '#FEFBF1',
  Yellow200: '#FDF8E2',
  Yellow300: '#FCF3CF',
  Yellow400: '#F7E392',
  Yellow500: '#F2CE40',
  Yellow600: '#EABE10',
  Yellow700: '#E0B300',
  Yellow800: '#786108',
  Yellow900: '#423605',

  Orange100: '#FEF9F1',
  Orange200: '#FDF3E2',
  Orange300: '#FCECCF',
  Orange400: '#F7D492',
  Orange500: '#F2B440',
  Orange600: '#EA9E10',
  Orange700: '#D28D0F',
  Orange800: '#785108',
  Orange900: '#422D05',

  OrangeRed100: '#FEF4F1',
  OrangeRed200: '#FDE7E2',
  OrangeRed300: '#FCD8CF',
  OrangeRed400: '#F7A692',
  OrangeRed500: '#F26440',
  OrangeRed600: '#EA3C10',
  OrangeRed700: '#D2360F',
  OrangeRed800: '#781F08',
  OrangeRed900: '#421105',

  Red100: '#FEF1F1',
  Red200: '#FDE2E2',
  Red300: '#FCCFCF',
  Red400: '#F79191',
  Red500: '#F24040',
  Red600: '#E91010',
  Red700: '#D20F0F',
  Red800: '#770808',
  Red900: '#420505',

  Pink100: '#FEF0F8',
  Pink200: '#FDE2F2',
  Pink300: '#FCCFEA',
  Pink400: '#F791CF',
  Pink500: '#F240AB',
  Pink600: '#EA1093',
  Pink700: '#D20F84',
  Pink800: '#78084B',
  Pink900: '#420529',

  Purple100: '#FCF0FE',
  Purple200: '#F9E2FD',
  Purple300: '#F6CFFC',
  Purple400: '#EA91F7',
  Purple500: '#DA40F2',
  Purple600: '#CC10EA',
  Purple700: '#B90FD2',
  Purple800: '#680878',
  Purple900: '#3A0542',

  Violet100: '#F5F0FF',
  Violet200: '#F5F1FE',
  Violet300: '#DECFFC',
  Violet400: '#B391F7',
  Violet500: '#7B40F2',
  Violet600: '#5910EA',
  Violet700: '#500FD2',
  Violet800: '#2D0878',
  Violet900: '#190542',
};
